import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Review.css"

const Review = () => {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>


            <div className="container-lg p-lg-4 mb-5"  >
                <div className='OurHostingServices Dosis'>
                    <h2 className='mb-0 pb-0'>
                        Client Feedback
                    </h2>
                    {/* <p>
                        Choose from our flexible plans to suit your growing needs. Shared, VPS, <br />
                        Dedicated, and Cloud hosting options available.
                    </p> */}
                </div>
                <div className="row pb-4 p-lg-3 d-flex justify-content-center align-items-center text-center laila-semibold">
                    <Carousel responsive={responsive}
                        autoPlay={true}
                        swipeable={true}
                        draggable={true}
                        infinite={true}
                        partialVisible={false}
                        showDots={true}
                    >

                        <div>
                            <div className='ClientSays'>
                                {/* <img className='ClientSaysImge' src={require("../Img/ReviewUp1.png")} alt="Review" title='Review' /> */}
                                <div className='ClientSays-TestimonialSection'>
                                    <h3>
                                        Perennial Technologies Pvt Ltd
                                    </h3>
                                    {/* <h6>
                                        Ceo, Hosting
                                    </h6> */}
                                    <div>
                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#feda12" }} >
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                        </ul>
                                    </div>
                                    <p>
                                        iCloudSoft Technology's is  expertise and commitment to
                                        seamless integration transformed our workflow.
                                        Their responsive support and tailored
                                        solutions significantly enhanced our team's productivity.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='ClientSays'>
                                {/* <img className='ClientSaysImge' src={require("../Img/Review2.png")} alt="Review" title='Review' /> */}
                                <div className='ClientSays-TestimonialSection'>
                                    <h3>
                                        NCCS
                                    </h3>
                                    {/* <h6>
                                        Ceo, Hosting
                                    </h6> */}
                                    <div>
                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#feda12" }} >
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                        </ul>
                                    </div>
                                    <p>
                                        iCloudsoft Technology has proven to be an invaluable
                                        partner for our IT support needs. They take has minimized
                                        downtime and improved our overall efficiency.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='ClientSays'>
                                {/* <img className='ClientSaysImge' src={require("../Img/Review2.png")} alt="Review" title='Review' /> */}
                                <div className='ClientSays-TestimonialSection'>
                                    <h3>
                                        The Merakis Ventures
                                    </h3>
                                    {/* <h6>
                                        Ceo, Hosting
                                    </h6> */}
                                    <div>
                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#feda12" }} >
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                        </ul>
                                    </div>
                                    <p>
                                        iCloudSoft Technology has consistently delivered exceptional
                                        software and website development services.
                                        Highly recommend iCloudSoft
                                        for anyone looking to elevate their digital presence.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='ClientSays'>
                                {/* <img className='ClientSaysImge' src={require("../Img/Review2.png")} alt="Review" title='Review' /> */}
                                <div className='ClientSays-TestimonialSection'>
                                    <h3>
                                        Imperial Motorb Engine Tubes Ltd
                                    </h3>
                                    {/* <h6>
                                        Ceo, Hosting
                                    </h6> */}
                                    <div>
                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#feda12" }} >
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                        </ul>
                                    </div>
                                    <p>
                                        iCloudSoft Technology’s cloud services are unparalleled
                                        in reliability and performance. They seamlessly
                                        support both individual and business needs.
                                        Their customer support is responsive and knowledgeable.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </Carousel>
                </div>
            </div>


        </>
    )
}

export default Review